import React from 'react'
import { Link } from 'gatsby'

import '../styles/navbar.scss'
import Logo from '../images/logo.png'
import NosotrosIcon from '../images/nosotros.svg'
import ProyectosIcon from '../images/proyectos.svg'
import InvierteIcon from '../images/invierte.svg'
import BlogIcon from '../images/blog.svg'
import ContactoIcon from '../images/contacto.svg'
import PostventaIcon from '../images/Postventa.png'

import NavbarItem from './NavbarItem'

const items = [
	{
		key: 1,
		title: 'Sobre Nosotros',
		icon: NosotrosIcon,
		to: '/#nosotros',
		route: 'nosotros',
		type: "internal",
		h: true
	},
	{
		key: 2,
		title: 'Proyectos y servicios',
		icon: ProyectosIcon,
		to: '/#proyectos',
		route: 'proyectos',
		type: "internal",
		h: true
	},
	{
		key: 3,
		title: 'Invierte con nosotros',
		icon: InvierteIcon,
		to: '/#invierte',
		route: 'invierte',
		type: "internal",
		h: false
	},
	/* {
		key: 4,
		title: 'Avance de obra',
		icon: BlogIcon,
		to: '/blog',
		route: 'blog',
		type: "internal",
		h: false
	}, */
	{
		key: 5,
		title: 'Contáctanos',
		icon: ContactoIcon,
		to: '/#contacto',
		route: 'contacto',
		type: "internal",
		h: false
	},
	{
		key: 6,
		title: 'Postventa /PQRS',
		icon: PostventaIcon,
		to: 'https://www.sico.com.co/Controles/CRM/Postventa/Externos/sicoPostventa.html?esquemaColor=[ESQUEMACOLOR]&intIdEmpresa=2237',
		route: '',
		type: "external",
		h: false
	}
]

export default function Navbar({ currentPath }) {
	return (
		<nav className="nav">
			<Link to="/" className="nav__logo">
				<img src={Logo} alt="Logo Grupo Ínsula" />
			</Link>
			<ul className="nav__list">
				{items.map((item) => {
					return <NavbarItem item={item} key={item.key} currentPath={currentPath} />
				})}
			</ul>
		</nav>
	)
}
