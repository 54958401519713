import React, { useState } from 'react'
import { navigate } from 'gatsby'

import '../styles/navbar-mobile.scss'

import NosotrosIcon from '../images/nosotros.svg'
import ProyectosIcon from '../images/proyectos.svg'
import InvierteIcon from '../images/invierte.svg'
import BlogIcon from '../images/blog.svg'
import ContactoIcon from '../images/contacto.svg'
import PostventaIcon from '../images/Postventa.png'
import Logo from '../images/logo.png'

const items = [
	{
		key: 1,
		title: 'Sobre Nosotros',
		icon: NosotrosIcon,
		to: '/#nosotros',
		route: 'nosotros',
		type: "internal",
		h: true
	},
	{
		key: 2,
		title: 'Proyectos y servicios',
		icon: ProyectosIcon,
		to: '/#proyectos',
		route: 'proyectos',
		type: "internal",
		h: true
	},
	{
		key: 3,
		title: 'Invierte con nosotros',
		icon: InvierteIcon,
		to: '/#invierte',
		route: 'invierte',
		type: "internal",
		h: false
	},
	{
		key: 4,
		title: 'Blog',
		icon: BlogIcon,
		to: '/blog',
		route: 'blog',
		type: "internal",
		h: false
	},
	{
		key: 5,
		title: 'Contáctanos',
		icon: ContactoIcon,
		to: '/#contacto',
		route: 'contacto',
		type: "internal",
		h: false
	},
	{
		key: 6,
		title: 'Postventa /PQRS',
		icon: PostventaIcon,
		to: 'https://www.sico.com.co/Controles/CRM/Postventa/Externos/sicoPostventa.html?esquemaColor=[ESQUEMACOLOR]&intIdEmpresa=2237',
		route: '',
		type: "external",
		h: false
	}
]

export default function NavbarResponsive() {
	const [ showMenu, setShowMenu ] = useState(false)

	const openMenu = () => {
		setShowMenu(true)
	}

	const closeMenu = () => {
		setShowMenu(false)
	}

	function goTo(r) {
		closeMenu()
		navigate(r)
	}

	return (
		<div className="navbar-m">
			<div className="navbar-m__bars-container" onClick={openMenu}>
				<div className="navbar-m__bar" />
				<div className="navbar-m__bar" />
				<div className="navbar-m__bar" />
			</div>

			<div className={`navbar-m__menu ${showMenu ? 'active' : ''}`}>
				<div className="navbar-m__menu__close" onClick={closeMenu} />
				<div className="navbar-m__menu__content">
					<img
						src={Logo}
						alt="Logo Grupo Ínsula"
						className="navbar-m__menu__logo"
						onClick={() => goTo('/')}
					/>
					{items.map((item) => {
						return (
							item.type == "internal"
							?
							<div className="nav__link" key={item.key} onClick={() => goTo(item.to)}>
								<li className={`nav__item ${item.h ? 'nav__item--h' : ''}`}>
									<img src={item.icon} alt="icon" />
									<p className={`${item.active ? 'active' : ''}`}>{item.title}</p>
								</li>
							</div>
							:
							<a 
								className="nav__link" 
								key={item.key} 
								href={item.to}
								target="_blank"
								rel="noopener noreferrer"
							>
								<li className={`nav__item ${item.h ? 'nav__item--h' : ''}`}>
									<img src={item.icon} alt="icon" />
									<p className={`${item.active ? 'active' : ''}`}>{item.title}</p>
								</li>
							</a>
						)
					})}
				</div>
			</div>
		</div>
	)
}
