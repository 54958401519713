import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link } from 'gatsby'
import '../styles/navbar.scss'

export default function NavbarItem({ item, currentPath }) {
	return (
		item.type == "internal"
		?
		<Link
			to={item.to}
			className={`nav__link ${currentPath.pathname.indexOf(item.route) >= 0 ||
			currentPath.hash.indexOf(item.route) >= 0
				? 'active'
				: ''}`}
		>
			<li className={`nav__item ${item.h ? 'nav__item--h' : ''} ${!item.active ? 'nav__item--inactive' : ''}`}>
				<img src={item.icon} alt="icon" />
				<p className={`${item.active ? 'active' : ''}`}>{item.title}</p>
			</li>
		</Link>
		:
		<a
			href={item.to}
			target="_blank"
			rel="noopener noreferrer"
			className={`nav__link`}
		>
			<li className={`nav__item ${item.h ? 'nav__item--h' : ''} ${!item.active ? 'nav__item--inactive' : ''}`}>
				<img src={item.icon} alt="icon" />
				<p className={`${item.active ? 'active' : ''}`}>{item.title}</p>
			</li>
		</a>
	)
}
