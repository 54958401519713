/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Navbar from "./Navbar"
import NavbarMobile from "./NavbarResponsive"
import IconWhatsApp from "../images/icon_whatsapp.svg"
import TopTexture from "../images/texture_top.png"
import BottomTexture from "../images/texture_bottom.png"

// import 'bootstrap/dist/css/bootstrap.min.css'
import "../styles/global.scss"

const Layout = ({ children, currentPath }) => {
  // const data = useStaticQuery(graphql`
  // 	query SiteTitleQuery {
  // 		site {
  // 			siteMetadata {
  // 				title
  // 			}
  // 		}
  // 	}
  // `)

  // return (
  // 	<Fragment>
  // 		<Navbar />
  // 		<div
  // 			style={{
  // 				margin: `0 auto`,
  // 				maxWidth: 960,
  // 				padding: `0 1.0875rem 1.45rem`
  // 			}}
  // 		>
  // 			<main>{children}</main>
  // 			<footer>
  // 				© {new Date().getFullYear()}, Built with
  // 				{` `}
  // 				<a href="https://www.gatsbyjs.org">Gatsby</a>
  // 			</footer>
  // 		</div>
  // 	</Fragment>
  // )

  return (
    <div className="main-container">
      {/* <Link to="/" className="nav__logo">
				<img src={Logo} alt="Logo Grupo Ínsula" />
			</Link> */}
      <Navbar currentPath={currentPath} />
      <NavbarMobile />
      <div />
      <img className="top-texture" src={TopTexture} alt="textura" />
      <main>{children}</main>
      <a
        target="_blank"
        href="https://wa.me/573204266032?text=Quiero%20más%20información%20de%20los%20proyectos"
        className="whatsapp"
        rel="noopener noreferrer"
      >
        <img src={IconWhatsApp} alt="Icono de Whatsapp" />
      </a>
      <img className="bottom-texture" src={BottomTexture} alt="textura" />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
